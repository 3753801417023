/**
 * @description 字典缓存方法
 */
import {localStorage} from "../storage";

const CACKE_KEYS = {
    DICT: "dict"
};

/**
 * @description 字典缓存类
 */
export default class DictCache {
    public static setDict(data: any): void {
        localStorage.set(CACKE_KEYS.DICT, data);
    }

    public static getDict(): any {
        return localStorage.get(CACKE_KEYS.DICT);
    }

    public static remove(): void {
        localStorage.remove(CACKE_KEYS.DICT);
    }
}