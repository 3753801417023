"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePagination = void 0;
var vue_1 = require("vue");
function usePagination() {
    // 当前页码
    var page = (0, vue_1.ref)(1);
    // 每页数据条数
    var count = (0, vue_1.ref)(10);
    // 总数据条数
    var totalRow = (0, vue_1.ref)(0);
    var currentPageDataSize = (0, vue_1.ref)(0);
    var lastPage = (0, vue_1.ref)(1);
    /**
     * @description 更新page
     * @param data
     */
    var updatePage = function (data) {
        // 设置总条数
        totalRow.value = data.total;
        // 得出当前页的数据条数,主要是为了删除的时候方便，如果删除的那条正好是当前页的最后一条，则page要进行--
        currentPageDataSize.value = data.data.length;
        lastPage.value = data.last_page;
    };
    /**
     * @description 纠正页码
     */
    var correctPage = function () {
        if (currentPageDataSize.value == 1 && page.value > 1) {
            page.value--;
        }
    };
    /**
     * @description 更新列表
     * @param pageNum
     * @param loadFn
     */
    var update = function (pageNum, loadFn) {
        page.value = pageNum;
        loadFn();
    };
    /**
     * @description 获取分页数据
     */
    var getPagination = function () {
        return {
            page: page.value,
            count: count.value
        };
    };
    /**
     * @description 重置分页
     * @param loadFn
     */
    var reload = function (loadFn) {
        page.value = 1;
        if (loadFn) {
            loadFn();
        }
    };
    return {
        updatePage: updatePage,
        totalRow: totalRow,
        getPagination: getPagination,
        update: update,
        reload: reload,
        page: page,
        count: count,
        correctPage: correctPage
    };
}
exports.usePagination = usePagination;
