/**
 * @description:sessionStorage
 */

/**
 * @description:设置sessionStorage值
 * @param key:键
 * @param value:值
 * @author zhaxianhe
 */
export const set = (key: string, value: any) => {
    try {
        //判断当前value是否是json
        if (value && typeof value === 'object') {
            value = JSON.stringify(value);
        }
        sessionStorage.setItem(key, value);
    } catch (e) {
        console.error(e);
    }
};

/**
 * @description:获取sessionStorage值
 * @param key:键
 * @returns Object/String
 * @author zhaxianhe
 */
export const get = (key: string) => {
    let str: any = sessionStorage.getItem(key);
    try {
        return JSON.parse(str);
    } catch (e) {
        return str;
    }
};

/**
 * @description:删除sessionStorage中的元素
 * @param key:键
 * @author zhaxianhe
 */
export const remove = (key: string) => {
    sessionStorage.removeItem(key);
};

/**
 * @description:清空sessionStorage中的所有元素
 * @author zhaxianhe
 */
export const clear = () => {
    sessionStorage.clear();
};

/**
 * @description:获取sessionStorage中所有的元素长度
 * @return Number
 * @author zhaxianhe
 */
export const getLength = () => {
    return sessionStorage.length;
};