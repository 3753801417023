import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import routes from "./routes";
import MicroAppCache from "@hview/hview-utils/lib/cache/micro";
import UserCache from "@hview/hview-utils/lib/cache/user";


/**
 * @description 创建微应用路由
 * @return MicroAppRoute[]
 */
const createMicroAppRoute = (): RouteRecordRaw[] => {
  let microAppRoute: RouteRecordRaw[] = [];
  const micros: any = MicroAppCache.getMicroApp() || [];
  if (!micros) {
    console.error("未找到微应用配置");
    return [];
  }
  for (let i = 0; i < micros.length; i++) {
    let micro = micros[i];
    microAppRoute.push({
      path: `${micro.path}/:path*`,
      name: micro.name,
      component: () => import("@/components/core/micro-page/index.vue")
    });
  }
  return microAppRoute;
}
routes.push(...createMicroAppRoute());

const router = createRouter({
  history: createWebHistory(),
  routes
});


router.beforeEach((to, from, next) => {
  // 获取token，若没有token，代表未登录
  const token: string | null = UserCache.getToken();
  if (!token && to.path != "/login") {
    next("/login");
  }
  if (token && to.path == "/login") {
    next("/");
  }
  next();
});

export default router
