"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_instance_1 = require("./src/http-instance");
__exportStar(require("./src/types"), exports);
var Http = /** @class */ (function () {
    function Http() {
    }
    /**
     * @description get 请求
     * @param url: string 请求路径
     * @param params: RequestParams 请求参数
     */
    Http.get = function (url, params) {
        return this.instance.get(url, params);
    };
    /**
     * @description post 请求
     * @param url: string 请求路径
     * @param data: RequestParams 请求参数
     * @param config: AxiosRequestConfig 请求配置
     */
    Http.post = function (url, data, config) {
        return this.instance.post(url, data, config);
    };
    /**
     * @description put 请求
     * @param url: string 请求路径
     * @param data: RequestParams 请求参数
     * @param config: AxiosRequestConfig 请求配置
     */
    Http.put = function (url, data, config) {
        return this.instance.put(url, data, config);
    };
    /**
     * @description delete请求
     * @param url: string 请求路径
     * @param params: RequestParams 请求参数
     */
    Http.delete = function (url, params) {
        return this.instance.delete(url, params);
    };
    // 创建实例
    Http.instance = new http_instance_1.default().getInstance();
    return Http;
}());
exports.default = Http;
