"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { key: 0 };
var vue_3 = require("vue");
var dict_1 = require("@hview/hview-utils/lib/cache/dict");
var ModeEnum;
(function (ModeEnum) {
    // 文本,回显模式
    ModeEnum["TEXT"] = "text";
})(ModeEnum || (ModeEnum = {}));
/**
 * 数据源类型
 */
var DataSourceEnum;
(function (DataSourceEnum) {
    // 默认
    DataSourceEnum["DEFAULT"] = "default";
    // 自定义
    DataSourceEnum["CUSTOM"] = "custom";
})(DataSourceEnum || (DataSourceEnum = {}));
exports.default = (0, vue_1.defineComponent)({
    __name: 'index',
    props: {
        mode: { default: "" },
        dataSource: { default: "default" },
        labelField: { default: "label" },
        valueField: { default: "value" },
        dictKey: {},
        modelValue: {},
        list: {}
    },
    setup: function (__props) {
        var instance = (0, vue_3.getCurrentInstance)();
        /**
         * 字典list
         */
        var dictList = (0, vue_3.ref)([]);
        /**
         * 显示的文本
         */
        var dictText = (0, vue_3.ref)("");
        /**
         * 父组件类型
         */
        var parentComponentType = (0, vue_3.ref)("");
        /**
         * 模式类型枚举
         */
        var props = __props;
        var init = function () {
            // 非text模式自动查找父组件类型，自动匹配
            if (props.mode !== "text") {
                var parentRef = instance.parent;
                if (parentRef && parentRef.type) {
                    var name = parentRef.type.name;
                    switch (name) {
                        case "ElRadioGroup":
                            parentComponentType.value = "radio";
                            break;
                        case "ElOptions":
                            parentComponentType.value = "select";
                            break;
                        case "ElCheckboxGroup":
                            parentComponentType.value = "checkbox";
                            break;
                    }
                }
            }
            // 自定义list
            if (props.dataSource == DataSourceEnum.CUSTOM) {
                if (!props.list || props.list.length == 0) {
                    return;
                }
                dictList.value = props.list;
            }
            else { // 直接读取缓存
                if (!props.dictKey) {
                    throw new Error("请填写dict key");
                }
                var dict = dict_1.default.getDict();
                var dictDataList = dict[props.dictKey];
                if (!dictDataList) {
                    throw new Error("\u672A\u627E\u5230\u5B57\u5178\u9879\uFF0Ckey\uFF1A\u3010".concat(props.dictKey, "\u3011"));
                }
                dictList.value = dictDataList;
            }
            // 有值代表回显
            if (props.modelValue || props.modelValue == 0) {
                var item = dictList.value.find(function (item) { return item[props.valueField] + "" == props.modelValue + ""; });
                if (item) {
                    dictText.value = item[props.labelField];
                }
                else {
                    dictText.value = "--";
                }
            }
            else {
                dictText.value = "--";
            }
        };
        init();
        (0, vue_3.watch)(function () { return props.modelValue; }, function () {
            init();
        });
        (0, vue_3.watch)(function () { return props.list; }, function () {
            init();
        });
        return function (_ctx, _cache) {
            var _component_el_option = (0, vue_2.resolveComponent)("el-option");
            var _component_el_radio = (0, vue_2.resolveComponent)("el-radio");
            var _component_el_checkbox = (0, vue_2.resolveComponent)("el-checkbox");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [
                (_ctx.mode == 'text')
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_1, (0, vue_2.toDisplayString)(dictText.value), 1))
                    : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 1 }, [
                        (parentComponentType.value == 'select')
                            ? ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 0 }, (0, vue_2.renderList)(dictList.value, function (item, index) {
                                return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_option, {
                                    label: item[_ctx.labelField],
                                    value: item[_ctx.valueField],
                                    key: index
                                }, null, 8, ["label", "value"]));
                            }), 128))
                            : (0, vue_2.createCommentVNode)("", true),
                        (parentComponentType.value == 'radio')
                            ? ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 1 }, (0, vue_2.renderList)(dictList.value, function (item, index) {
                                return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_radio, {
                                    label: item[_ctx.labelField],
                                    value: item[_ctx.valueField],
                                    key: index
                                }, null, 8, ["label", "value"]));
                            }), 128))
                            : (0, vue_2.createCommentVNode)("", true),
                        (parentComponentType.value == 'checkbox')
                            ? ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 2 }, (0, vue_2.renderList)(dictList.value, function (item, index) {
                                return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_checkbox, {
                                    label: item[_ctx.labelField],
                                    value: item[_ctx.valueField],
                                    key: index
                                }, null, 8, ["label", "value"]));
                            }), 128))
                            : (0, vue_2.createCommentVNode)("", true)
                    ], 64))
            ]));
        };
    }
});
