"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var storage_1 = require("../storage");
var CACHEKEYS = {
    MENU: "menu"
};
var MenuCache = /** @class */ (function () {
    function MenuCache() {
    }
    /**
     * @description 设置菜单
     * @param menu
     */
    MenuCache.setMenu = function (menu) {
        storage_1.localStorage.set(CACHEKEYS.MENU, menu);
    };
    /**
     * @description 获取菜单
     */
    MenuCache.getMenu = function () {
        var menu = storage_1.localStorage.get(CACHEKEYS.MENU);
        if (menu) {
            return menu;
        }
        return null;
    };
    /**
     * @description 删除菜单
     */
    MenuCache.removeMenu = function () {
        storage_1.localStorage.remove(CACHEKEYS.MENU);
    };
    return MenuCache;
}());
exports.default = MenuCache;
