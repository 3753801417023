/**
 * @description 微应用工具类
 */
import {MicroMessage} from "../cache/micro";
import UserCache from "../cache/user";
// import mainMicroApp from '@micro-zoe/micro-app'
export default class MicroUtils {

    // 获取microApp实例
    private static microApp = (window as any).microApp;


    /**
     * @description 子应用向基座应用发送数据
     * @param data MicroData
     */
    public static dispatch(data: MicroMessage): void  {
        if ((window as any).__MICRO_APP_ENVIRONMENT__) {
            this.microApp.dispatch(data);
        }
    }

    /**
     * @description 设置全局监听
     * @param data
     */
    public static setGlobalData(data: MicroMessage): void {
        if ((window as any).__MICRO_APP_ENVIRONMENT__) {
            this.microApp.setGlobalData(data);
        }
    }

    /**
     * @description 跳转到登录
     */
    public static toLogin(): void {
        (window as any).$scoket.disconnect();
        (window as any).$scoket = null;
        UserCache.clearAll();
        if ((window as any).__MICRO_APP_BASE_APPLICATION__) {
            window.location.href = "/login";
        } else {
            this.microApp.router.getBaseAppRouter().replace("/login");
        }
    }
}