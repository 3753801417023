import axios from 'axios';
import type { AxiosError, AxiosResponse, AxiosInstance} from 'axios';
import {NameValuePair} from "./types";
import {development, prod} from "@config/application";
import UserCache, {Tenant} from "@hview/hview-utils/lib/cache/user";
// import lodash from "lodash/debounce";
import { debounce } from 'lodash'

import SidCache from "@hview/hview-utils/lib/cache/sid";
import {Notice} from "@hview/hview-vue3/packages";
import MicroUtils from "@hview/hview-utils/lib/tools/micro-utils";

export default class HttpInstance {
    /**
     * 实例
     * @private
     */
    private instance!: AxiosInstance;

    /**
     * header头
     * @private
     */
    private header!: NameValuePair;

    constructor() {
        this.instance = axios.create({
            baseURL: process.env.NODE_ENV === 'development' ? development.server.baseurl : prod.server.baseurl,
            timeout: 30000
        });
    }

    /**
     * @description 设置header头
     * @param header
     */
    public setHeader(header: NameValuePair): HttpInstance {
        this.header = header;
        return this;
    }

    /**
     * @description 获取axios实例
     */
    public getInstance(): AxiosInstance {
        this.setRequestInterceptors();
        this.setResponseInterceptors();
        return this.instance;
    }

    private error = debounce((errMsg: string) => {
        Notice.error(errMsg);
    }, 1000, {
        leading: true,
        trailing: false
    });

    /**
     * @description 设置请求前拦截器
     */
    private setRequestInterceptors():void {
        this.instance.interceptors.request.use((config: any) => {
            //获取缓存中的token
            config.headers["sid"] = SidCache.getSid();
            // @ts-ignore
            const tenant: Tenant[] | null = UserCache.getDefaultTenant() || null;
            if (tenant && tenant.length > 0) {
                config.headers["tenant"] = tenant[0] + "";
            }
            const token = UserCache.getToken();
            if (token) {
                config.headers["token"] = token;
            }
            //判断是否有自定义header
            if (this.header) {
                for (let key in this.header) {
                    config.headers[key] = this.header[key];
                }
            }
            return config;
        }, (error: AxiosError) => {
            return Promise.reject(error);
        });
    }

    private toLogin = debounce(() => {
        MicroUtils.toLogin();
    }, 1000, {
        leading: true,
        trailing: false
    });

    /**
     * @description 设置响应后拦截器
     */
    private setResponseInterceptors():void {
        this.instance.interceptors.response.use((response: AxiosResponse): any => {
            let  { data } = response;
            //  成功
            if (data.code == 1) {
                return Promise.resolve(data);
            }
            // 失败
            if (data.code == 0) {
                this.error(data.msg);
            }
            return Promise.reject(data);
        }, (error: AxiosError) => {
            let { response } = error;
            if (error.code == "ERR_NETWORK") {
                this.error("网络异常，请稍后重试");
            }
            if (response && response.status === 401) {
                this.toLogin();
                return;
            }
            return Promise.reject(error);
        });
    }

}


export const setSid = () => {

}
