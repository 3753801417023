<template>
  <router-view/>
</template>

<script lang="ts" setup name="HRouterView">

</script>

<style scoped>

</style>