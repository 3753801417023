import { io } from "socket.io-client";
import UserCache from "../../hview-utils/lib/cache/user";
import {getSocketPath} from "@config/application";
import {useMessageNotice} from "@hview/hview-vue3/packages/hooks";
import {mitter} from "@hview/hview-vue3/packages";
import { ElMessageBox } from "element-plus";
const {notify} = useMessageNotice();

useMessageNotice()

/**
 * @description socket.io
 */
export default class Socket {
    // socket实例
    private socketIO: any = null;
    private isShowConfirm: boolean = false;
    /**
     * @description 创建连接
     */
    public connect () {

        const token = UserCache.getToken();
        if (!token) {
            console.error("当前用户未登录，socketIO无法进行初始化...");
            return;
        }
        if (!this.socketIO) {
            let platform = "pc";
            if (UserCache.getUserInfo() && UserCache.getUserInfo().id) {
                platform += '_' + UserCache.getUserInfo().id;
            }
            this.socketIO = io(getSocketPath(), {
                reconnectionDelayMax: 10000,
                auth: {
                    token: token,
                    platform: platform
                },
                transports: ['websocket', 'polling'],
                timeout: 5000
            });

            this.socketIO.on("connect", () => {
                console.log("socketIO已连接...");
            });
            this.socketIO.on("disconnect", () => {
                console.log("连接断开...");
                if (this.isShowConfirm) {
                    return;
                }
                this.isShowConfirm = true;
                ElMessageBox.confirm(
                    '为了保证及时收到消息，请重新连接！',
                    '连接中断',
                    {
                        confirmButtonText: '重新连接',
                        cancelButtonText: '暂不连接',
                        type: 'warning',
                    }
                ).then(() => {
                    this.connect();
                }).finally(() => {
                    this.isShowConfirm = false;
                });
            });




            this.onPrivateMessage();
            this.onGroupMessage();
        } else if (!this.socketIO.connected) {
            console.info("...重新连接")
            this.socketIO.connect();
        }
        return this.socketIO;
    }


    /**
     * @description 监听私聊消息
     * @private
     */
    private onPrivateMessage() {
        this.socketIO.on("private msg", (data: any) => {
            notify(data);
            // 发送全局消息
            mitter.emit("on-chat-msg", data);
        });
    }

    /**
     * @description 监听群聊消息
     * @private
     */
    private onGroupMessage () {
        this.socketIO.on("group msg", (data: any) => {
            // 调用通知
            notify(data);
            // 发送全局通知
            mitter.emit("on-chat-msg", data);
        });
    }

    /**
     * @description 获取socketIO连接状态
     * @return {boolean|*}
     */
    public getSocketStatus () {
        if (this.socketIO) {
            return this.socketIO.connected;
        }
        return false;
    }

    /**
     * @description 断开socketIO
     */
    public disconnect () {
        // 判断当前socket对象是否存在，并且当前的socket状态是否为已连接状态
        if (this.socketIO && this.getSocketStatus()) {
            this.socketIO.disconnect();
            this.socketIO = null;
        }
    }

    /**
     * @description 发送私聊消息
     */
    public sendPrivateMsg (data: any) {
        data["category"] = "private";
        // 判断当前的socket状态
        if (!this.getSocketStatus()) {
            this.connect();
        }
        return new Promise(((resolve, reject) => {
            this.socketIO.emit("private msg", data, (res: any) => {
                if (res.success) {
                    resolve(data);
                } else {
                    reject(false);
                }
            });
        }));
    }

    /**
     * @description 发送群聊消息
     */
    sendGroupMsg (data: any) {
        data["category"] = "service";
        // 判断当前的socket状态
        if (!this.getSocketStatus()) {
            this.connect();
        }
        return new Promise(((resolve, reject) => {
            this.socketIO.emit("group msg", data, (res: any) => {
                if (res.success) {
                    resolve(data);
                } else {
                    reject(false);
                }
            });
        }));
    }
}


/**
 * @description 消息类型
 * @type {{IMAGE: string, TEXT: string, AUDIO: string}}
 */
export const MessageType  = {
    TEXT: "text",// 文本
    AUDIO: "audio",//语音
    IMAGE: "image",//图片
    ACTION: "action",//动作，已读，撤销
    MEDICALQUESTION: "MedicalQuestion", //picc
    SYSTEM: "system", //系统消息
    CARD: "card" //卡片消息
}