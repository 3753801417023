<template>
  <div class="h-pagination">
    <el-pagination :default-page-size="count" @current-change="currentChangeEvent" :current-page="currentPage"  background :layout="layout" :total="total" />
  </div>
</template>

<script lang="ts" setup name="HPagination">
  import {ref, defineProps, withDefaults, defineEmits} from "vue";
  import {usePagination} from "../../../hooks/use-pagination";
  const {count} = usePagination();

  const emit = defineEmits(["change"]);
  interface Props {
    total: number;
    currentPage: number;
    layout?: string
  }

  withDefaults(defineProps<Props>(), {
    total: 0,
    currentPage: 1,
    layout: "total, prev, pager, next"
  });

  /**
   * @description 页码改变触发事件
   * @param value
   */
  const currentChangeEvent = (value: number) => {
    emit("change", value);
  }

</script>

<style lang="scss">
.h-pagination {
  padding-right: 10px;
}
</style>