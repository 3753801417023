"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var menu = [];
exports.default = menu;
// {
//     id: 1,
//     text: "工作台",
//     icon: "icon-gongzuotai",
//     path: "/",
//     app: ""
// }, {
//     id: 2,
//     text: "消息",
//     icon: "icon-xiaoxi",
//     path: "/chat",
//     app: "doctor"
// }, {
//     id: 3,
//     text: "患者",
//     icon: "icon-huanzheguanli2",
//     path: "/patient",
//     app: "doctor"
// }, {
//     id: 4,
//     text: "文章",
//     icon: "icon-wodewenzhang",
//     path: "/article",
//     app: "doctor"
// }
