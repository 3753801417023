import HLayout from "./components/h-layout";
import HEditor from "./components/h-editor";
import HText from "./components/h-text";
import HDict from "./components/h-dict";
export * from "./components/h-layout";

import HPagination from "./components/h-pagination";

import HContainer from "./components/h-container";
import HUpload from "./components/h-upload";
import HRouterView from "./components/h-router-view/index.vue";
export * from "./utils/notification";

export * from "./hooks";

import mitter from "./utils/mitter";
export {mitter};


export * from "./hooks/time-from";

const install = (Vue: any) => {
    for (const key in components) {
        Vue.component(key, components[key]);
    }
}
const components: any = {
    HLayout,
    HContainer,
    HRouterView,
    HPagination,
    HText,
    HUpload,
    HDict
};

if (typeof window !== 'undefined' && (window as any).Vue) {
    install((window as any).Vue);
}

export default {
    install
}

export {
    HLayout,
    HContainer,
    HRouterView,
    HPagination,
    HEditor,
    HText,
    HUpload,
    HDict
};