/**
 * @description 退出登录
 * @param params
 * @return {Promise<unknown>|Promise<undefined>|*}
 */
import Http from "@hview/hview-http";

export const userLogoutApi = () => Http.post("/addons/ask/api.user/logout");

export const getuserauth = () => Http.post("/api/index/getuserauth");
